declare var CONFIG: {
  api_url_stp_service: string,
  api_url_stp_token: string,
  click_super_redirect_url: string,
  click_super_logout_url: string,
  aws_enabled: string,
  features: {
    stp_registration_active: boolean
  },
  environment: string
};

const IS_UNDER_TEST = process.env.JEST_WORKER_ID !== undefined;

export const API_URL_STP_SERVICE = IS_UNDER_TEST ? "/api/stp" : CONFIG.api_url_stp_service;

export const API_URL_STP_TOKEN = IS_UNDER_TEST ? "/api/token" : CONFIG.api_url_stp_token;

// TODO: Remove
// Only dead code is still calling PAYEVNT directly; remove this line when that dead
// code is removed
export const API_URL_PAYEVNT = IS_UNDER_TEST ? "not-in-use" : "not-in-use";

export const CLICK_SUPER_REDIRECT_URL = IS_UNDER_TEST ? "/" : CONFIG.click_super_redirect_url;

export const CLICK_SUPER_LOGOUT_URL = IS_UNDER_TEST ? "/" : CONFIG.click_super_logout_url;

export const CLICK_SUPER_ORGANISATION_URL = `${CLICK_SUPER_REDIRECT_URL}/Organisation`;

export const AWS_ENABLED = IS_UNDER_TEST ? false : CONFIG.aws_enabled === 'true';

export const SERVICE_PROVIDER = "Comply Path";

export const DEFAULT_PAGE_SIZE = 10;

export const ALLOWED_PAGE_SIZES = [10, 20, 50, 100]

export const STATUS_COLORS = [
  "#09c3b7",
  "#2684ff",
  "#796eff",
  "#6cc40b",
  "#5e5e63",
];

export const ISO_DATE_FORMAT = "yyyy-MM-dd";
export const AU_NUMERIC_DATE_FORMAT = "dd/MM/yyyy";
export const AU_FULL_DATE_FORMAT = "d MMM yyyy";
export const AU_FULL_TIME_FORMAT = `${AU_FULL_DATE_FORMAT}, hh:mm a`;

export const AUSTRALIAN_STATES_AND_TERRITORIES = [
  "AAT",
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
];

// ISO 3166-1 alpha-2
export const COUNTRY_CODES = [
  { value: "ad", label: "Andorra" },
  { value: "ae", label: "United Arab Emirates" },
  { value: "af", label: "Afghanistan" },
  { value: "ag", label: "Antigua and Barbuda" },
  { value: "ai", label: "Anguilla" },
  { value: "al", label: "Albania" },
  { value: "am", label: "Armenia" },
  { value: "ao", label: "Angola" },
  { value: "aq", label: "Antarctica" },
  { value: "ar", label: "Argentina" },
  { value: "as", label: "American Samoa" },
  { value: "at", label: "Austria" },
  { value: "au", label: "Australia" },
  { value: "aw", label: "Aruba" },
  { value: "ax", label: "Åland Islands" },
  { value: "az", label: "Azerbaijan" },
  { value: "ba", label: "Bosnia and Herzegovina" },
  { value: "bb", label: "Barbados" },
  { value: "bd", label: "Bangladesh" },
  { value: "be", label: "Belgium" },
  { value: "bf", label: "Burkina Faso" },
  { value: "bg", label: "Bulgaria" },
  { value: "bh", label: "Bahrain" },
  { value: "bi", label: "Burundi" },
  { value: "bj", label: "Benin" },
  { value: "bl", label: "Saint Barthélemy" },
  { value: "bm", label: "Bermuda" },
  { value: "bn", label: "Brunei Darussalam" },
  { value: "bo", label: "Bolivia, Plurinational State of" },
  { value: "bq", label: "Bonaire, Sint Eustatius and Saba" },
  { value: "br", label: "Brazil" },
  { value: "bs", label: "Bahamas" },
  { value: "bt", label: "Bhutan" },
  { value: "bv", label: "Bouvet Island" },
  { value: "bw", label: "Botswana" },
  { value: "by", label: "Belarus" },
  { value: "bz", label: "Belize" },
  { value: "ca", label: "Canada" },
  { value: "cc", label: "Cocos (Keeling) Islands" },
  { value: "cd", label: "Congo, Democratic Republic of the" },
  { value: "cf", label: "Central African Republic" },
  { value: "cg", label: "Congo" },
  { value: "ch", label: "Switzerland" },
  { value: "ci", label: "Côte d'Ivoire" },
  { value: "ck", label: "Cook Islands" },
  { value: "cl", label: "Chile" },
  { value: "cm", label: "Cameroon" },
  { value: "cn", label: "China" },
  { value: "co", label: "Colombia" },
  { value: "cr", label: "Costa Rica" },
  { value: "cu", label: "Cuba" },
  { value: "cv", label: "Cabo Verde" },
  { value: "cw", label: "Curaçao" },
  { value: "cx", label: "Christmas Island" },
  { value: "cy", label: "Cyprus" },
  { value: "cz", label: "Czechia" },
  { value: "de", label: "Germany" },
  { value: "dj", label: "Djibouti" },
  { value: "dk", label: "Denmark" },
  { value: "dm", label: "Dominica" },
  { value: "do", label: "Dominican Republic" },
  { value: "dz", label: "Algeria" },
  { value: "ec", label: "Ecuador" },
  { value: "ee", label: "Estonia" },
  { value: "eg", label: "Egypt" },
  { value: "eh", label: "Western Sahara" },
  { value: "er", label: "Eritrea" },
  { value: "es", label: "Spain" },
  { value: "et", label: "Ethiopia" },
  { value: "fi", label: "Finland" },
  { value: "fj", label: "Fiji" },
  { value: "fk", label: "Falkland Islands (Malvinas)" },
  { value: "fm", label: "Micronesia, Federated States of" },
  { value: "fo", label: "Faroe Islands" },
  { value: "fr", label: "France" },
  { value: "ga", label: "Gabon" },
  {
    value: "gb",
    label: "United Kingdom of Great Britain and Northern Ireland",
  },
  { value: "gd", label: "Grenada" },
  { value: "ge", label: "Georgia" },
  { value: "gf", label: "French Guiana" },
  { value: "gg", label: "Guernsey" },
  { value: "gh", label: "Ghana" },
  { value: "gi", label: "Gibraltar" },
  { value: "gl", label: "Greenland" },
  { value: "gm", label: "Gambia" },
  { value: "gn", label: "Guinea" },
  { value: "gp", label: "Guadeloupe" },
  { value: "gq", label: "Equatorial Guinea" },
  { value: "gr", label: "Greece" },
  { value: "gs", label: "South Georgia and the South Sandwich Islands" },
  { value: "gt", label: "Guatemala" },
  { value: "gu", label: "Guam" },
  { value: "gw", label: "Guinea-Bissau" },
  { value: "gy", label: "Guyana" },
  { value: "hk", label: "Hong Kong" },
  { value: "hm", label: "Heard Island and McDonald Islands" },
  { value: "hn", label: "Honduras" },
  { value: "hr", label: "Croatia" },
  { value: "ht", label: "Haiti" },
  { value: "hu", label: "Hungary" },
  { value: "id", label: "Indonesia" },
  { value: "ie", label: "Ireland" },
  { value: "il", label: "Israel" },
  { value: "im", label: "Isle of Man" },
  { value: "in", label: "India" },
  { value: "io", label: "British Indian Ocean Territory" },
  { value: "iq", label: "Iraq" },
  { value: "ir", label: "Iran, Islamic Republic of" },
  { value: "is", label: "Iceland" },
  { value: "it", label: "Italy" },
  { value: "je", label: "Jersey" },
  { value: "jm", label: "Jamaica" },
  { value: "jo", label: "Jordan" },
  { value: "jp", label: "Japan" },
  { value: "ke", label: "Kenya" },
  { value: "kg", label: "Kyrgyzstan" },
  { value: "kh", label: "Cambodia" },
  { value: "ki", label: "Kiribati" },
  { value: "km", label: "Comoros" },
  { value: "kn", label: "Saint Kitts and Nevis" },
  { value: "kp", label: "Korea, Democratic People's Republic of" },
  { value: "kr", label: "Korea, Republic of" },
  { value: "kw", label: "Kuwait" },
  { value: "ky", label: "Cayman Islands" },
  { value: "kz", label: "Kazakhstan" },
  { value: "la", label: "Lao People's Democratic Republic" },
  { value: "lb", label: "Lebanon" },
  { value: "lc", label: "Saint Lucia" },
  { value: "li", label: "Liechtenstein" },
  { value: "lk", label: "Sri Lanka" },
  { value: "lr", label: "Liberia" },
  { value: "ls", label: "Lesotho" },
  { value: "lt", label: "Lithuania" },
  { value: "lu", label: "Luxembourg" },
  { value: "lv", label: "Latvia" },
  { value: "ly", label: "Libya" },
  { value: "ma", label: "Morocco" },
  { value: "mc", label: "Monaco" },
  { value: "md", label: "Moldova, Republic of" },
  { value: "me", label: "Montenegro" },
  { value: "mf", label: "Saint Martin, (French part)" },
  { value: "mg", label: "Madagascar" },
  { value: "mh", label: "Marshall Islands" },
  { value: "mk", label: "North Macedonia" },
  { value: "ml", label: "Mali" },
  { value: "mm", label: "Myanmar" },
  { value: "mn", label: "Mongolia" },
  { value: "mo", label: "Macao" },
  { value: "mp", label: "Northern Mariana Islands" },
  { value: "mq", label: "Martinique" },
  { value: "mr", label: "Mauritania" },
  { value: "ms", label: "Montserrat" },
  { value: "mt", label: "Malta" },
  { value: "mu", label: "Mauritius" },
  { value: "mv", label: "Maldives" },
  { value: "mw", label: "Malawi" },
  { value: "mx", label: "Mexico" },
  { value: "my", label: "Malaysia" },
  { value: "mz", label: "Mozambique" },
  { value: "na", label: "Namibia" },
  { value: "nc", label: "New Caledonia" },
  { value: "ne", label: "Niger" },
  { value: "nf", label: "Norfolk Island" },
  { value: "ng", label: "Nigeria" },
  { value: "ni", label: "Nicaragua" },
  { value: "nl", label: "Netherlands" },
  { value: "no", label: "Norway" },
  { value: "np", label: "Nepal" },
  { value: "nr", label: "Nauru" },
  { value: "nu", label: "Niue" },
  { value: "nz", label: "New Zealand" },
  { value: "om", label: "Oman" },
  { value: "pa", label: "Panama" },
  { value: "pe", label: "Peru" },
  { value: "pf", label: "French Polynesia" },
  { value: "pg", label: "Papua New Guinea" },
  { value: "ph", label: "Philippines" },
  { value: "pk", label: "Pakistan" },
  { value: "pl", label: "Poland" },
  { value: "pm", label: "Saint Pierre and Miquelon" },
  { value: "pn", label: "Pitcairn" },
  { value: "pr", label: "Puerto Rico" },
  { value: "ps", label: "Palestine, State of" },
  { value: "pt", label: "Portugal" },
  { value: "pw", label: "Palau" },
  { value: "py", label: "Paraguay" },
  { value: "qa", label: "Qatar" },
  { value: "re", label: "Réunion" },
  { value: "ro", label: "Romania" },
  { value: "rs", label: "Serbia" },
  { value: "ru", label: "Russian Federation" },
  { value: "rw", label: "Rwanda" },
  { value: "sa", label: "Saudi Arabia" },
  { value: "sb", label: "Solomon Islands" },
  { value: "sc", label: "Seychelles" },
  { value: "sd", label: "Sudan" },
  { value: "se", label: "Sweden" },
  { value: "sg", label: "Singapore" },
  { value: "sh", label: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "si", label: "Slovenia" },
  { value: "sj", label: "Svalbard and Jan Mayen" },
  { value: "sk", label: "Slovakia" },
  { value: "sl", label: "Sierra Leone" },
  { value: "sm", label: "San Marino" },
  { value: "sn", label: "Senegal" },
  { value: "so", label: "Somalia" },
  { value: "sr", label: "Suriname" },
  { value: "ss", label: "South Sudan" },
  { value: "st", label: "Sao Tome and Principe" },
  { value: "sv", label: "El Salvador" },
  { value: "sx", label: "Sint Maarten, (Dutch part)" },
  { value: "sy", label: "Syrian Arab Republic" },
  { value: "sz", label: "Eswatini" },
  { value: "tc", label: "Turks and Caicos Islands" },
  { value: "td", label: "Chad" },
  { value: "tf", label: "French Southern Territories" },
  { value: "tg", label: "Togo" },
  { value: "th", label: "Thailand" },
  { value: "tj", label: "Tajikistan" },
  { value: "tk", label: "Tokelau" },
  { value: "tl", label: "Timor-Leste" },
  { value: "tm", label: "Turkmenistan" },
  { value: "tn", label: "Tunisia" },
  { value: "to", label: "Tonga" },
  { value: "tr", label: "Turkey" },
  { value: "tt", label: "Trinidad and Tobago" },
  { value: "tv", label: "Tuvalu" },
  { value: "tw", label: "Taiwan, Province of China" },
  { value: "tz", label: "Tanzania, United Republic of" },
  { value: "ua", label: "Ukraine" },
  { value: "ug", label: "Uganda" },
  { value: "um", label: "United States Minor Outlying Islands" },
  { value: "us", label: "United States of America" },
  { value: "uy", label: "Uruguay" },
  { value: "uz", label: "Uzbekistan" },
  { value: "va", label: "Holy See" },
  { value: "vc", label: "Saint Vincent and the Grenadines" },
  { value: "ve", label: "Venezuela, Bolivarian Republic of" },
  { value: "vg", label: "Virgin Islands, British" },
  { value: "vi", label: "Virgin Islands, U.S." },
  { value: "vn", label: "Viet Nam" },
  { value: "vu", label: "Vanuatu" },
  { value: "wf", label: "Wallis and Futuna" },
  { value: "ws", label: "Samoa" },
  { value: "ye", label: "Yemen" },
  { value: "yt", label: "Mayotte" },
  { value: "za", label: "South Africa" },
  { value: "zm", label: "Zambia" },
  { value: "zw", label: "Zimbabwe" },
];

export const PAYMENT_ARRANGEMENT_TERMINATION_CODES = ["T"];

export const RESIDENCY_TAX_PURPOSES_PERSON_STATUS_CODES = [
  "Resident",
  "Non-Resident",
  "Working Holiday Maker",
];

export const PAYMENT_ARRANGEMENT_PAYMENT_BASIS_CODES = [
  {
    label: "Full time",
    value: "F",
  },
  {
    label: "Part time",
    value: "P",
  },
  {
    label: "Casual",
    value: "C",
  },
  {
    label: "Labour hire",
    value: "L",
  },
  {
    label: "Pension or Annunity",
    value: "S",
  },
];

export const ETP_CODES = ["B", "D", "N", "O", "P", "R", "S", "T"];

export const LUMP_SUM_PAYMENT_A_TYPES = ["R", "T"];

export const ALLOWANCE_TYPES = [
  "Car",
  "Transport",
  "Laundry",
  "Meals",
  "Travel",
  "Other",
];

export const DEDUCTION_TYPES = ["Fees", "Workplace Giving"];

export const PAYEVNT_TEMPLATE = {
  payevnt: {
    rp: {
      addressDetailsPostal: {
        countryC: "",
        line1T: "",
        line2T: "",
        localityNameT: "",
        postcodeT: "",
        stateOrTerritoryC: "",
      },
      australianBusinessNumberId: "",
      declaration: {
        signatoryIdentifierT: "",
        signatureD: "",
        statementAcceptedI: "",
      },
      electronicContact: {
        electronicMailAddressT: "",
        telephoneMinimalN: "",
      },
      organisationDetailsOrganisationBranchC: "",
      organisationName: {
        detailsOrganisationalNameT: "",
        personUnstructuredNameFullNameT: "",
      },
      payroll: {
        amendmentI: "",
        incomeTaxAndRemuneration: {
          payAsYouGoWithholdingTaxWithheldA: "",
          totalGrossPaymentsWithholdingA: "",
        },
        interactionRecordCt: "",
        interactionTransactionId: "",
        messageTimestampGenerationDt: "",
        paymentRecordTransactionD: "",
      },
      softwareInformationBusinessManagementSystemId: "",
      withholdingPayerNumberId: "",
    },
  },
};

export const PAYEVNTEMP_TEMPLATE = {
  payevntemp: {
    payee: {
      addressDetails: {
        countryC: "",
        line1T: "",
        line2T: "",
        localityNameT: "",
        postcodeT: "",
        stateOrTerritoryC: "",
      },
      electronicContact: {
        electronicMailAddressT: "",
        telephoneMinimalN: "",
      },
      employerConditions: {
        employmentEndD: undefined,
        employmentStartD: undefined,
      },
      identifiers: {
        australianBusinessNumberId: "",
        employmentPayrollNumberId: "",
        taxFileNumberId: "",
      },
      onboarding: {
        declaration: {
          signatureD: undefined,
          statementAcceptedI: "",
        },
        tfnd: {
          incomeTaxPayAsYouGoWithholdingStudyAndTrainingLoanRepaymentI: "",
          paymentArrangementPaymentBasisC: "",
          paymentArrangementTerminationC: "",
          residencyTaxPurposesPersonStatusC: "",
          studentLoanStudentFinancialSupplementSchemeI: "",
          taxOffsetClaimTaxFreeThresholdI: "",
        },
      },
      personDemographicDetails: {
        birthDm: "",
        birthM: "",
        birthY: "",
      },
      personNameDetails: {
        familyNameT: "",
        givenNameT: "",
        otherGivenNameT: "",
      },
      remunerationIncomeTaxPayAsYouGoWithholding: {
        allowanceCollection: {
          allowance: [],
        },
        deductionCollection: {
          deduction: [],
        },
        employmentTerminationPaymentCollection: {
          employmentTerminationPayment: [],
        },
        incomeFringeBenefitsReportable: {
          exemptIncomeFringeBenefitsReportableA: "",
          taxableIncomeFringeBenefitsReportableA: "",
        },
        individualNonBusiness: {
          communityDevelopmentEmploymentProjectA: "",
          exemptForeignEmploymentIncomeA: "",
          grossA: "",
          taxWithheldA: "",
        },
        jointPetroleumDevelopmentAreaPayment: {
          a: "",
          foreignWithholdingA: "",
          taxWithheldA: "",
        },
        labourHireArrangementPayment: {
          grossA: "",
          taxWithheldA: "",
        },
        paymentToForeignResident: {
          foreignWithholdingA: "",
          grossA: "",
          taxWithheldA: "",
        },
        payrollPeriod: {
          endD: undefined,
          payrollEventFinalI: "",
          startD: undefined,
        },
        specifiedByRegulationPayment: {
          grossA: "",
          taxWithheldA: "",
        },
        superannuationContribution: {
          employerContributionsSuperannuationGuaranteeA: "",
          employerReportableA: "",
          ordinaryTimeEarningsA: "",
        },
        unusedAnnualOrLongServiceLeavePayment: {
          lumpSumBA: "",
          lumpSumDA: "",
          lumpSumEA: "",
          lumpSumPaymentA: {
            lumpSumAA: "",
            lumpSumAC: "",
          },
        },
        voluntaryAgreement: {
          grossA: "",
          taxWithheldA: "",
        },
        workingHolidayMaker: {
          grossA: "",
          taxWithheldA: "",
        },
      },
    },
  },
};

export const EMPLOYMENT_TERMINATION_PAYMENT_TEMPLATE = {
  typeC: "",
  paymentRecordPaymentEffectiveD: undefined,
  superannuationTaxFreeComponentA: "",
  superannuationEmploymentTerminationTaxableComponentTotalA: "",
  taxWithheldA: "",
};

export const ALLOWANCE_ITEM = {
  typeC: "",
  otherAllowanceTypeDe: "",
  individualNonBusinessEmploymentAllowancesA: "",
};

export const DEDUCTION_ITEM = {
  typeC: "",
  a: "",
};

// STP feature flags
export const FEATURES = {
  STP_REGISTRATION_ACTIVE: IS_UNDER_TEST ? true : CONFIG.features.stp_registration_active
}

export const ENVIRONMENT = IS_UNDER_TEST ? "local" : CONFIG.environment;


