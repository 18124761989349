const ExternalLink = ({ text, link, children, className, target }) => {
  return (
    <a
      className={className || "styled-link"}
      href={link}
      rel="noopener noreferrer"
      target={target || "_blank"}
    >
      {children ? children : text}
    </a>
  );
};

export default ExternalLink;
